import React from "react"
import Layout from "../components/layout/Layout.js"
import { Column100 } from "../components/column/Column.js"
import { Row, BlogRow } from "../components/row/Row.js"
import { TextMedium } from "../components/text/Text"
import { BlogTitle } from "../components/title/Title"
import { Section } from "../components/section/Section.js"
import Seo from '../components/seo'

function CourseRegistrationSuccess() {
  return (
    <Layout>
    <Seo title="Course Registration - Success" />
    <Section>
      <BlogRow>
          <BlogTitle isPrimary title="Thanks for joining our training session!" />
          <TextMedium
            textMedium="Please check your email address for a confirmation message and for additional 
            details regarding the course agenda."
            />
          <TextMedium
            textMedium="For the moment, all our courses are held online. We will send you the URL for joining 
            the training 24 hours in advance. Keep an eye on your inbox and feel free to message us if you have questions!"
            />
          <TextMedium textMedium="Thanks again and see you soon!" />
      </BlogRow>
    </Section>
  </Layout>
)
}

export default CourseRegistrationSuccess